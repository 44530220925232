<template>
  <!-- <component v-if="ready" :is="userHome"></component> -->
  <div></div>
</template>
<script>
// import Meetings from '@/views/Meetings';
// import Now from '@/views/Now';
// import Actions from '@/views/Actions';

export default {
  name: 'Home',
  components: {},
  mounted() {
    this.$router.replace({ name: this.$store.getters['preferenceStartPage'] });
  },
  computed: {
    // ready() {
    //   return this.$store.getters['preferencesLoaded'];
    // },
    // userHome() {
    //   return this.$store.getters['preferenceStartPage'];
    // },
  },
};
</script>
